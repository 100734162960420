import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {useState} from "react";
import "./App.css";
import StoryBlockCreator from "./StoryBlockCreator";
import {getEnvVar} from "./utils/env";

function GeneralPage() {
    const [storyPrompt, setStoryPrompt] = useState("");
    const [storyLevels, setStoryLevels] = useState(1);
    const environment = (
        (window as any).env.RAILWAY_ENVIRONMENT_NAME || "local"
    ).toUpperCase();

    const handleSubmit = async () => {
        const PRODUCER_URL = getEnvVar('REACT_APP_PRODUCER_URL');
        if (!PRODUCER_URL) {
            console.error("PRODUCER_URL environment variable is not set");
            return;
        }

        const AUTH_TOKEN = getEnvVar('REACT_APP_API_KEY')
        if (!AUTH_TOKEN) {
            console.error("API_KEY environment variable is not set");
            return;
        }


        const formData = new FormData();
        formData.append("title", "Story Title");
        formData.append("content", storyPrompt);

        try {
            const response = await fetch(
                `${PRODUCER_URL}/producer_start?story_block_levels=${storyLevels}`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: AUTH_TOKEN,
                    },
                }
            );

            const data = await response.json();
            console.log("Response:", data);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="App">
            <div className="container">
                <div className="title-container">
                    <h1 className="title">Crowd Control</h1>
                    <span className="environment-flair">{environment}</span>
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        className="text-input"
                        placeholder="Enter story prompt..."
                        value={storyPrompt}
                        onChange={(e) => setStoryPrompt(e.target.value)}
                    />
                    <input
                        type="number"
                        className="number-input"
                        placeholder="Levels"
                        min="1"
                        max="5"
                        value={storyLevels}
                        onChange={(e) => setStoryLevels(parseInt(e.target.value))}
                    />
                    <button className="create-button" onClick={handleSubmit}>
                        Create Story
                    </button>
                </div>
            </div>
        </div>
    );
}


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StoryBlockCreator/>}/>
                <Route path="/general" element={<GeneralPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;