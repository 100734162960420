import React, {useState, useEffect} from 'react';
import './App.css'; // Make sure this file includes the given styles
import {v4 as uuidv4} from "uuid";
import { DemoStartPayload, StoryBlock } from './types';
import { ProducerApi } from './utils/producer_api';


const GREEN = "#4CAF50";
const PURPLE = "#A500FF";
const YELLOW = "#FFA500";
const RED = "#FF0000"

const BLOCKS: StoryBlock[] = [
    {
      "premise": "A group of people are sitting at a dinner table for Christmas dinner, the characters are defined in the list below. They are waiting for the turkey to be served, it’s taking longer than they expected because Linda forgot to preheat the oven. Bored and hungry the group discusses moistness preferences for turkey. The scene ends as Zoe announces she’s decided not to go to college and rather to devote her life to planting trees in the Amazon.",
      "id": "string_uuid_1",
      "parent_id": null,
      "level": 1,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "The group is nonplussed with Zoe’s announcement. Kyle begins talking about how bitcoin has reached $100,000 and how it’s time for everyone to HODL.",
      "id": "string_uuid_2",
      "parent_id": "string_uuid_1",
      "level": 2,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "Uncle Frank responds to Zoe’s announcement with his conspiracy theories around Jeff Bezos and Amazon, he’s confused the name of the rainforest with the company.",
      "id": "string_uuid_3",
      "parent_id": "string_uuid_1",
      "level": 2,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "Uncle Frank is impressed with Kyle’s enthusiasm about Bitcoin and starts spewing his own theories of crypto. Finally dinner is served.",
      "id": "string_uuid_4",
      "parent_id": "string_uuid_2",
      "level": 3,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "Zoe complains about the environmental impact crypto has on the planet. Kyle defends bitcoin. Finally dinner is served.",
      "id": "string_uuid_5",
      "parent_id": "string_uuid_2",
      "level": 3,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "Tired of hearing yet another of Uncle Frank’s theories, Emily tries to engage Zoe in conversation about her plans in The Amazon. Finally dinner is served.",
      "id": "string_uuid_6",
      "parent_id": "string_uuid_3",
      "level": 3,
      "status": "NOT_PROCESSED"
    },
    {
      "premise": "Tired of hearing yet another of Uncle Frank’s theories, Linda talks about the importance of Christmas and family. Finally dinner is served.",
      "id": "string_uuid_7",
      "parent_id": "string_uuid_3",
      "level": 3,
      "status": "NOT_PROCESSED"
    }
  ]

function generateId(counter) {
    return `block_${counter}`;
}

const simplifyStoryBlockIds = (storyBlocks: StoryBlock[]) => {
    const idMapping = {};
    const mappedStoryBlocks = [];
    let counter = 1;
    storyBlocks.forEach((storyBlock) => {
        const mappedParentId = storyBlock.parent_id ? idMapping[storyBlock.parent_id] : null;
        const mappedBlock = {
            ...storyBlock,
            id: generateId(counter),
            parent_id: mappedParentId
        }
        mappedStoryBlocks.push(mappedBlock);
        idMapping[storyBlock.id] = mappedBlock.id;
        counter += 1;
    })
    return mappedStoryBlocks;
}

export default function StoryBlockCreator() {
    const queryParamStoryId = new URLSearchParams(window.location.search).get('story_id');
    const [useBaftaCharacters, setUseBaftaCharacters] = useState(true);
    const [storyBlocks, setStoryBlocks] = useState(BLOCKS);
    const [storyId, setStoryId] = useState(queryParamStoryId);
    const [idCounter, setIdCounter] = useState(1);
    const [idMapping, setIdMapping] = useState({});
    const [error, setError] = useState(null);

    // State for creating new blocks
    const [newPremise, setNewPremise] = useState("");
    const [newParentId, setNewParentId] = useState("");

    // For editing existing blocks, including parent editing
    const [editingBlockId, setEditingBlockId] = useState(null);
    const [editedPremise, setEditedPremise] = useState("");
    const [editedParentId, setEditedParentId] = useState("");

    const [narrativeEngineStarted, setNarrativeEngineStarted] = useState(false);
    const [isStarting, setIsStarting] = useState(false);


    // Check if there is already a root block
    const rootBlockExists = storyBlocks.some(b => b.parent_id === null);
    
    const producerApi = new ProducerApi();

    function handleAddBlock(e) {
        e.preventDefault();
        if (!newPremise.trim()) return;

        // If trying to create a root block but one already exists, stop
        if (!newParentId && rootBlockExists) {
            alert("A root block already exists. You cannot create another root block.");
            return;
        }

        let level = 1;
        if (newParentId) {
            const parentBlock = storyBlocks.find(b => b.id === newParentId);
            if (parentBlock) {
                level = parentBlock.level + 1;
            }
        }

        const newBlockId = generateId(idCounter);
        const newBlock = {
            premise: newPremise,
            id: newBlockId,
            parent_id: newParentId || null,
            level: level,
            status: "NOT_PROCESSED"
        };

        const updatedBlocks = [...storyBlocks, newBlock];

        // Update state
        setStoryBlocks(updatedBlocks);
        setIdCounter(idCounter + 1);
        setNewPremise("");

        // Reset newParentId only if it is invalid in the updated list
        if (!updatedBlocks.some(block => block.id === newParentId)) {
            setNewParentId("");
        }
    }

    function handleEditBlock(blockId) {
        // Toggle editing mode. If turning on, set editedPremise and editedParentId to current block's values.
        if (editingBlockId === blockId) {
            // Cancel editing if clicking again
            setEditingBlockId(null);
            setEditedPremise("");
            setEditedParentId("");
        } else {
            const block = storyBlocks.find(b => b.id === blockId);
            if (block) {
                setEditingBlockId(blockId);
                setEditedPremise(block.premise);
                setEditedParentId(block.parent_id || "");
            }
        }
    }

    function handleSaveEdit(blockId) {
        // Check for unique root constraint if editedParentId is ""
        if (editedParentId === "" && rootBlockExists && storyBlocks.find(b => b.id === blockId).parent_id !== null) {
            alert("A root block already exists. You cannot make this block a root block.");
            return;
        }

        // Compute new level
        let level = 1;
        if (editedParentId) {
            const parentBlock = storyBlocks.find(b => b.id === editedParentId);
            if (parentBlock) {
                level = parentBlock.level + 1;
            }
        }

        const updatedBlocks = storyBlocks.map(b => {
            if (b.id === blockId) {
                return {
                    ...b,
                    premise: editedPremise,
                    parent_id: editedParentId || null,
                    level: level,
                    status: b.status || "NOT_PROCESSED"
                };
            }
            return b;
        });

        // After changing a parent, we must ensure children levels are still correct
        // Update children levels recursively
        function updateChildLevels(parentId, parentLevel) {
            updatedBlocks.forEach(child => {
                if (child.parent_id === parentId) {
                    child.level = parentLevel + 1;
                    updateChildLevels(child.id, child.level);
                }
            });
        }

        updateChildLevels(blockId, level);

        setStoryBlocks(updatedBlocks);
        setEditingBlockId(null);
        setEditedPremise("");
        setEditedParentId("");
    }

    function deleteBlockAndChildren(blockId) {
        // Get all descendants of this block
        const descendants = getAllDescendants(blockId, storyBlocks);
        const idsToDelete = [blockId, ...descendants];
        const filteredBlocks = storyBlocks.filter(b => !idsToDelete.includes(b.id));
        setStoryBlocks(filteredBlocks);
    }

    function getAllDescendants(blockId, blocks) {
        let children = blocks.filter(b => b.parent_id === blockId).map(b => b.id);
        for (let childId of children) {
            children = children.concat(getAllDescendants(childId, blocks));
        }
        return children;
    }

    const finalJSON: DemoStartPayload = {
        use_bafta_characters: useBaftaCharacters,
        story_blocks: storyBlocks
    };

    const sendRequest = async (_) => {
        setNarrativeEngineStarted(false)
        setIsStarting(true)
        const finalJsonProcessed = structuredClone(finalJSON)
        const localIdMapping = {}

        finalJsonProcessed.story_blocks.forEach((storyBlock) => {
            if (!localIdMapping[storyBlock.id]) {
                localIdMapping[storyBlock.id] = uuidv4();
            }
            storyBlock.id = localIdMapping[storyBlock.id];

            // Map and replace parent_id
            if (storyBlock.parent_id) {
                if (!localIdMapping[storyBlock.parent_id]) {
                    localIdMapping[storyBlock.parent_id] = uuidv4();
                }
                storyBlock.parent_id = localIdMapping[storyBlock.parent_id];
            }
        })
        setIdMapping(localIdMapping)
        let responseData = null;
        try {
            responseData = await producerApi.startNarrativeEngine(finalJsonProcessed);
        } catch (error) {
            console.error("Error starting narrative engine:", error);
            setError("Error starting narrative engine")
        }
        setIsStarting(false)
        if (responseData) {
            setNarrativeEngineStarted(true)
            setStoryId(responseData.story_id);
            window.history.pushState({}, '', `/?story_id=${responseData.story_id}`);
        } else {
            setError("Error starting narrative engine")
        }
    }
    
    const getStoryBlocks = async () => {
        const storyBlocks = await producerApi.getStoryBlocks(storyId);
        const simplifiedStoryBlocks = simplifyStoryBlockIds(storyBlocks);
        setStoryBlocks(simplifiedStoryBlocks);
    }

    useEffect(() => {
        let intervalId;
        if (storyId) {
            // Initial fetch
            getStoryBlocks();
            
            // Set up polling every 5 seconds
            intervalId = setInterval(() => {
                getStoryBlocks();
            }, 5000);
        }

        // Cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [narrativeEngineStarted, storyId]); // Dependencies

    return (
        <div className="App">
            <div className="two-pane-layout">
                <div className='static-dashboard'>
                    <div className="title-container">
                        <h1 className="title">Story Block Creator</h1>
                    </div>

                    <div style={{marginBottom: "20px"}}>
                        <label style={{color: "white", fontSize: "0.5em"}}>
                            <input
                                type="checkbox"
                                checked={useBaftaCharacters}
                                onChange={(e) => setUseBaftaCharacters(e.target.checked)}
                                disabled={true}
                            />
                            Use BAFTA Characters
                        </label>
                    </div>
                    <h2 style={{color: "white", marginTop: "40px"}}>Add a New Story Block</h2>
                    <form onSubmit={handleAddBlock} style={{marginBottom: "20px"}}>
                        <div className="input-group" style={{marginBottom: "10px"}}>
              <textarea
                  className="text-input"
                  style={{width: "400px", height: "150px"}}
                  placeholder="Enter premise"
                  value={newPremise}
                  onChange={(e) => setNewPremise(e.target.value)}
              />
                        </div>
                        <h3 style={{color: "white", marginTop: "40px"}}>Parent</h3>
                        <div className="input-group" style={{marginBottom: "10px"}}>
                            <select
                                className="text-input"
                                style={{width: "320px"}}
                                value={newParentId}
                                onChange={(e) => setNewParentId(e.target.value)}
                            >
                                {/* If a root block already exists, do not show the empty parent option */}
                                <option value="">-- No Parent (new root) --</option>
                                {storyBlocks.map(block => (
                                    <option key={block.id} value={block.id}>
                                        {block.id} - {block.premise.substring(0, 30) + (block.premise.length > 30 ? "..." : "")}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="create-button" type="submit">Add Story Block</button>
                    </form>

                    <button className="start-narrative-engine" type="submit" onClick={sendRequest}>Start Narrative
                        Engine 🚀
                    </button>

                    {isStarting && <h3 style={{color: "white", marginTop: "40px"}}>Starting Narrative Engine...</h3>}
                    {narrativeEngineStarted && <h3 style={{color: "white", marginTop: "40px"}}>Started Narrative Engine</h3>}
                    {error && <h3 style={{color: "red", marginTop: "40px"}}>{error}</h3>}
                </div>

                <div className='scrollable-pane'>
                    <h2 style={{color: "white"}}>Existing Story Blocks</h2>
                    {storyBlocks.length === 0 ? (
                        <p style={{color: "white"}}>No blocks yet. Create the root block first!</p>
                    ) : (
                        <ul style={{
                            listStyle: "none",
                            padding: 0,
                            color: "white",
                            maxWidth: "600px",
                            margin: "0 auto"
                        }}>
                            {storyBlocks.map(block => (
                                <li key={block.id} style={{
                                    border: "1px solid #61dafb",
                                    borderRadius: "4px",
                                    padding: "10px",
                                    marginBottom: "10px"
                                }}>
                                    <div>
                                        <strong>ID:</strong> {block.id}<br/>
                                        <strong>Parent:</strong> {block.parent_id || "None"}<br/>
                                        <strong>Level:</strong> {block.level}<br/>
                                        <strong>Status:</strong> <span style={{
                                            color: block.status === "RENDERED" ? GREEN : 
                                                  block.status === "PROCESSED" ? PURPLE : 
                                                  block.status === "IN_PROGRESS" ? YELLOW : RED
                                        }}>{block.status}</span>
                                    </div>
                                    {editingBlockId === block.id ? (
                                        <div style={{marginTop: "10px"}}>
                      <textarea
                          className="text-input"
                          style={{width: "100%", minHeight: "60px"}}
                          value={editedPremise}
                          onChange={(e) => setEditedPremise(e.target.value)}
                      />
                                            <div style={{marginTop: "10px"}}>
                                                <label style={{color: "white"}}>
                                                    Parent:
                                                    <select
                                                        className="text-input"
                                                        style={{marginLeft: "10px"}}
                                                        value={editedParentId}
                                                        onChange={(e) => setEditedParentId(e.target.value)}
                                                    >
                                                        {/* If a root block exists and this block is currently non-root, it cannot become root */}
                                                        {
                                                            (!rootBlockExists || storyBlocks.find(b => b.id === block.id).parent_id === null) &&
                                                            <option value="">No Parent (Root)</option>
                                                        }
                                                        {storyBlocks.filter(b => b.id !== block.id).map(b => (
                                                            <option key={b.id} value={b.id}>
                                                                {b.id} - {b.premise.substring(0, 30) + (b.premise.length > 30 ? "..." : "")}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </label>
                                            </div>
                                            <div style={{marginTop: "10px"}}>
                                                <button className="create-button"
                                                        onClick={() => handleSaveEdit(block.id)}>Save
                                                </button>
                                                <button className="create-button" style={{
                                                    marginLeft: "10px",
                                                    backgroundColor: "#f44336",
                                                    color: "white"
                                                }} onClick={() => handleEditBlock(block.id)}>Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{marginTop: "10px"}}>
                                            <em>{block.premise}</em>
                                            <div style={{marginTop: "10px"}}>
                                                <button className="create-button"
                                                        onClick={() => handleEditBlock(block.id)}>Edit
                                                </button>
                                                <button className="create-button" style={{
                                                    marginLeft: "10px",
                                                    backgroundColor: "#f44336",
                                                    color: "white"
                                                }} onClick={() => deleteBlockAndChildren(block.id)}>Delete
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}

                    <h2 style={{color: "white"}}>JSON Output (NOTE: UUIDs will be added)</h2>
                    <pre style={{
                        background: "#333",
                        color: "#fff",
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "14px",
                        maxWidth: "600px",
                        margin: "0 auto"
                    }}>{JSON.stringify(finalJSON, null, 2)}
                    </pre>
                </div>
            </div>
        </div>
    );
}